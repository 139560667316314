import React from 'react';
import Layout from '../components/layout';
import {useTranslation} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";
import SEO from "../components/seo";

const Contact = () => {
    const {t} = useTranslation();
    return (
        <Layout pageID={'contact'}>
            <SEO title="Contact" />
            <section className="top">
                <h1>{t("contact")}</h1>
            </section>
            <section className="content">
                <h2>{t("orders")} :</h2>
                <a href="mailto:nomadsbrewingco@gmail.com">nomadsbrewingco@gmail.com</a>
            </section>
        </Layout>
    )
}

export default Contact;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "contact"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
